import React from "react"
import {StoreOnly, Banner} from "../components/layout"
import Img from "gatsby-image"
import { ProductDetail } from "../components/detail"
import { Avantages, Spacer, Button, Newsletter, Footer } from "../components/banners"
import { graphql, Link } from "gatsby"
import "../components/carousel.css"
import { Carousel } from "react-responsive-carousel"
import { Vignette } from "../components/vignette"
import Insta from "../components/insta"
import { idToProduct } from "../utils/utils"

const prod = (data) => (
  data.allMongodbHerokuD589Dff8Mbmjproducts.edges[Math.floor(Math.random()*9)].node
)

const Slide = ({ title, link, image }) => (
  <div style={{ height: "800px" }}>
    <Link to={link}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: "0",
        }}
      >
        <Img fluid={image} />
      </div>
      <div
        style={{
          position: "relative",
          zIndex: "999",
          left: "50%",
          transform: "translateX(-600px) translateY(300px)",
          textAlign: "left",
        }}
      >
        <div
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {title}
        </div>
        <Spacer v="2rem" />
        <div>
          <Button onClick={() => {}}>Découvrir</Button>
        </div>
      </div>
    </Link>
  </div>
)

export default ({ data }) => (
  <StoreOnly>
    <div
      style={{
        position: "static",
        top: 0,
        left: 0,
        right: 0,
        zIndex: "-1",
        height: "800px",
      }}
    >
      <Carousel // see options https://www.npmjs.com/package/react-responsive-carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
        interval={3000}
      >
        <Slide
          title="Nouvelle collection"
          link="/shop"
          image={data.file.childImageSharp.fluid}
        />
        <Slide
          title="Nos valeurs"
          link="/qui-sommes-nous"
          image={data.file.childImageSharp.fluid}
        />
        <Slide
          title="Où nous trouver"
          link="/ou-nous-trouver"
          image={data.file.childImageSharp.fluid}
        />
        <Slide
          title="Où nous trouver"
          link="/ou-nous-trouver"
          image={data.file.childImageSharp.fluid}
        />
      </Carousel>
    </div>

    <div style={{position: "absolute", top: '0', left: '0', width: '100%'}}>
    <div style={{ margin: `0 auto`, maxWidth: 1200, padding: `0 0` }}>

      <Banner />
      </div>
    </div>

    <div style={{ margin: `0 auto`, maxWidth: 1200, padding: `0 0` }}>
      <Spacer v="2rem" />

      <Img fixed={data.about.pics[0].localImage.small.fixed} />

      <div
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "0.15em",
          fontSize: "1.3rem",
          fontStyle: 'italic'
        }}
      >
        Look good
        <br />
        Do good
        <br />
        Feel good!
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
        <Vignette size="large" skipOrder={true} p={prod(data)}></Vignette>
        <Vignette skipOrder={true} p={prod(data)}></Vignette>
        <Vignette skipOrder={true} p={prod(data)}></Vignette>
      </div>

      <Spacer v="3rem" />

      <div style={{textAlign: 'center'}}>
        <Button to="/shop">Shop</Button>
      </div>

      <Spacer v="5rem" />

      <Avantages />

      <Spacer v="5rem" />

      <Newsletter />

      <Spacer v="5rem" />

      <Insta />

      <Footer />
    </div>
  </StoreOnly>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "AdobeStock_271954175.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMongodbHerokuD589Dff8Mbmjproducts {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
    ...StaticPics
  }
`
